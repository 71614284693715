import { defineStore } from 'pinia';
import { useFetchClient, useRoute } from '#imports';

import type { ICampaign } from '@/types';

export interface ICampaignState {
  campaigns: Array<ICampaign>;
}

export const useCampaignStore = defineStore('campaign', {
  state: (): ICampaignState => ({
    campaigns: [],
  }),
  getters: {
    getCampaignById: (state): ICampaign | any => {
      return (id?: string): ICampaign | null => state.campaigns?.find((campaign) => campaign.id === id) || null;
    },
    activeCampaigns: (state) => {
      return (
        state.campaigns?.filter((campaign) => {
          const route = useRoute();
          console.log(
            campaign?.action !== 'home' && campaign.id !== route.params?.research,
            route.params?.research,
            campaign.id
          );

          return campaign?.action !== 'home' && campaign.id !== route.params?.research;
        }) || []
      );
    },
  },
  actions: {
    async init() {
      const { data } = await useFetchClient('/api/campaigns');

      this.campaigns = data.value;
    },
  },
});
